.prj-img:hover {
    cursor: pointer;
}

.prj-title {
    font-weight: 600;
    font-size: 2em;
}

.prj-title:hover {
    cursor: pointer;
}

.prj-desc {
    font-size: 1.25em;
    font-weight: 200;
}

.skill-list {
    margin-bottom: 5%;
}

.blue-label {
    color: var(--blue);
    background-color: var(--blueBack);
    border-radius: 24px;
    border: none;
    font-weight: 600;
    padding: 2% 4%;
    margin: 2% 2% 4% 0%;
    width: fit-content;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.25em;
}

.pink-label {
    color: var(--pink);
    background-color: var(--pinkBack);
    border-radius: 24px;
    border: none;
    font-weight: 600;
    padding: 2% 4%;
    margin: 2% 2% 4% 0%;
    width: fit-content;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.25em;
}

.lavender-label {
    color: var(--lavender);
    background-color: var(--lavenderBack);
    border-radius: 24px;
    border: none;
    font-weight: 600;
    padding: 2% 4%;
    margin: 2% 2% 4% 0%;
    width: fit-content;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.25em;
}

.orange-label {
    color: var(--orange);
    background-color: var(--orangeBack);
    border-radius: 24px;
    border: none;
    font-weight: 600;
    padding: 2% 4%;
    margin: 2% 2% 4% 0%;
    width: fit-content;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.25em;
}

.mint-label {
    color: var(--mint);
    background-color: var(--mintBack);
    border-radius: 24px;
    border: none;
    font-weight: 600;
    padding: 2% 4%;
    margin: 2% 2% 4% 0%;
    width: fit-content;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.25em;
}
