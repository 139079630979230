@import url(https://fonts.googleapis.com/css?family=Work+Sans);
body {
  margin: 0;
  padding: 15px;
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-weight: bolder;
  color: #161616;
  font-size: 3em;
  margin: 10px 0px 10px 0px;
}

h2 {
  font-weight: 600;
  color: #161616;
  font-size: 2.5em;
  margin: 5px 0px 5px 0px;
}

h3 {
  font-weight: 600;
  color: #161616;
  font-size: 1.5em;
  margin: 5px 0px 5px 0px;
}

h4 {
  font-weight: 400;
  color: #161616;
  font-size: 1.2em;
  margin: 5px 0px 5px 0px;
}

p {
  font-weight: 100;
  color: #1a0c0c;
  font-size: 1em;
}


.App {
  text-align: left;
}

:root {
  --blue: #699CFF;
  --orange: #F3BB88;
  --pink: #F388C1;
  --mint: #8AE9DD;
  --lavender: #8E9DFD;

  --blueBack: #E1EBFF;
  --orangeBack: #FFE9D6;
  --pinkBack: #FFCFE9;
  --mintBack: #E6FDFA;
  --lavenderBack: #DEE2FF;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.home-link {
  float: left;
  font-weight: 800;
  font-size: 1.25em;
  padding: 1%;
}

.nav-link {
  font-weight: 600;
}

.footer {
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  min-height: calc(100%);
}

.made-with {
  float: left;
  padding: 1% 2% 2% 2%;
  font-size: 1.15em;
}

.heart { 
  max-width: 8%;
}

.icon {
 max-width: 80%;
}

.icon:hover {
  max-width: 80%;
  transform: translateY(-2px); 
}

.icon-pad {
  float: right;
  margin-left: 2%;
  margin-top: 1%;
}

li {
  float: right;
}

li a {
  color: #161616;
  display: block;
  text-align: center;
  padding: 14px 16px;
  font-size: 20px;
  font-weight: 400;
  text-decoration: none;
}

/* Change the link color to #111 (black) on hover */
li a:hover {
  background-color: 
}

.nav-link:hover {
  color: #699CFF;
  color: var(--blue);
}

@media only screen and (min-width: 600px) {
  .dropdown-container {
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .made-with {
    padding: 1% 2% 2% 2%;
    font-size: 1.15em;
  }

  li {
    float: none;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .icons {
    display: flex;
    flex-direction: row;
  }

  .home-link {
    display: none;
  }

  .nav-link {
    display: none;
  }

  /* Menu dropdown */
  .dropdown-container {
    position: relative;
    float: right;
  }
  
  .dropdown-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
  }

  .dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    width: 100px;
    padding: 10px;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }

  .dropdown li:hover {
    background-color: #ddd;
  }
}




.prj-img:hover {
    cursor: pointer;
}

.prj-title {
    font-weight: 600;
    font-size: 2em;
}

.prj-title:hover {
    cursor: pointer;
}

.prj-desc {
    font-size: 1.25em;
    font-weight: 200;
}

.skill-list {
    margin-bottom: 5%;
}

.blue-label {
    color: var(--blue);
    background-color: var(--blueBack);
    border-radius: 24px;
    border: none;
    font-weight: 600;
    padding: 2% 4%;
    margin: 2% 2% 4% 0%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.25em;
}

.pink-label {
    color: var(--pink);
    background-color: var(--pinkBack);
    border-radius: 24px;
    border: none;
    font-weight: 600;
    padding: 2% 4%;
    margin: 2% 2% 4% 0%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.25em;
}

.lavender-label {
    color: var(--lavender);
    background-color: var(--lavenderBack);
    border-radius: 24px;
    border: none;
    font-weight: 600;
    padding: 2% 4%;
    margin: 2% 2% 4% 0%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.25em;
}

.orange-label {
    color: var(--orange);
    background-color: var(--orangeBack);
    border-radius: 24px;
    border: none;
    font-weight: 600;
    padding: 2% 4%;
    margin: 2% 2% 4% 0%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.25em;
}

.mint-label {
    color: var(--mint);
    background-color: var(--mintBack);
    border-radius: 24px;
    border: none;
    font-weight: 600;
    padding: 2% 4%;
    margin: 2% 2% 4% 0%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.25em;
}


.hero {
    display: flex;
    flex-direction: row;
    padding: 10% 0% 10% 0%;
    width: 100%;
}

.hero-img {
    padding: 0% 5% 0% 5%;
}

img {
    max-width: 100%;
    height: auto;
}

.hero-txt {
    display: flex;
    flex-direction: column;
    padding: 0% 5% 0% 5%;
}

.accent-txt {
    color: var(--blue);
}

.underlined {
    padding-bottom: 2%;
    border-bottom: 3px solid black;
    font-size: 1.75em;
    max-width: 150px;
}

.blue-button {
    background-color: #E1EBFF; 
    border-radius: 24px;
    border: none;
    color: var(--blue);
    font-weight: 600;
    padding: 2% 4%;
    margin: 4% 0% 4% 0%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.25em;
    outline: none; 
    cursor: pointer;
}

.portfolio {
    margin: 0% 5% 0% 5%;
}


.projects {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-grow: 1;
    padding: 5% 10% 10% 10%;
}

@supports not (-ms-ime-align: auto) {
    .projects {
      justify-content: space-evenly;
      padding: 5%;
    }
  }

.link-style {
    text-decoration: none;
}

.prj {
    flex-direction: row;
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 1024px) {
    .projects {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 5%;
    }

    .hero {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: auto;
    }

    .hero-img {
        max-width: 100%;    
        max-height: 100%;
        width: 100%;
        text-align: center;
        padding: 0;
    }

    .hero-txt {
        padding: 10% 0% 10% 0%;
        text-align: center;
    }

    .underlined {
        text-align: center;
        margin: auto;
    }
}


.about-txt {
    padding: 10%; 
}

.flex-container {
    display: flex;
    flex-direction: row;
}

.description {
    line-height: 2em;
}

.profile-pic {
    max-width: 400px;
    max-height: 400px;
    margin-left: 10%;
    border: 10px solid var(--blueBack);
    border-radius: 10%;
}

.blue-link {
    color: var(--blue);
    border-bottom: 2px solid var(--blue) ;
    text-decoration: none;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
   .flex-container {
       flex-direction: column-reverse;
   }

   .profile-pic {
       width: 100%;
       margin: 20px 0px 20px 0px;
    
   }
}
.prj-icon {
    max-height: 100px;
}

.desc-txt {
    font-weight: 500;
}

.orange-label {
    color: var(--orange);
    background-color: var(--orangeBack);
    border-radius: 30px;
    border: none;
    font-weight: 600;
    padding: 1% 2%;
    margin: 2% 2% 4% 0%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.25em;
}

.lavender-label {
    color: var(--lavender);
    background-color: var(--lavenderBack);
    border-radius: 30px;
    border: none;
    font-weight: 600;
    padding: 1% 2%;
    margin: 2% 2% 4% 0%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.25em;
}

.pink-label {
    color: var(--pink);
    background-color: var(--pinkBack);
    border-radius: 30px;
    border: none;
    font-weight: 600;
    padding: 1% 2%;
    margin: 2% 2% 4% 0%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.25em;
}

.blue-label {
    color: var(--blue);
    background-color: var(--blueBack);
    border-radius: 30px;
    border: none;
    font-weight: 600;
    padding: 1% 2%;
    margin: 2% 2% 4% 0%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.25em;
}

.container {
    padding: 5%;
}

.prj-para {
    line-height: 2.5em;
}

.prj-img {
    max-width: 450px;
}

.img-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 2%;
    padding-bottom: 2%;
}
.container {
    padding: 5%;
}
.label {
    color: var(--pink);
    background-color: var(--pinkBack);
}

.container {
    padding: 5%;
}
.container {
    padding: 5%;
}
.container {
    padding: 5%;
}

.prj-para {
    line-height: 2.5em;
}

.prj-img {
    max-width: 450px;
}

.img-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 2%;
    padding-bottom: 2%;
}
