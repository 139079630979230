.App {
  text-align: left;
}

:root {
  --blue: #699CFF;
  --orange: #F3BB88;
  --pink: #F388C1;
  --mint: #8AE9DD;
  --lavender: #8E9DFD;

  --blueBack: #E1EBFF;
  --orangeBack: #FFE9D6;
  --pinkBack: #FFCFE9;
  --mintBack: #E6FDFA;
  --lavenderBack: #DEE2FF;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.home-link {
  float: left;
  font-weight: 800;
  font-size: 1.25em;
  padding: 1%;
}

.nav-link {
  font-weight: 600;
}

.footer {
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  min-height: calc(100%);
}

.made-with {
  float: left;
  padding: 1% 2% 2% 2%;
  font-size: 1.15em;
}

.heart { 
  max-width: 8%;
}

.icon {
 max-width: 80%;
}

.icon:hover {
  max-width: 80%;
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px); 
}

.icon-pad {
  float: right;
  margin-left: 2%;
  margin-top: 1%;
}

li {
  float: right;
}

li a {
  color: #161616;
  display: block;
  text-align: center;
  padding: 14px 16px;
  font-size: 20px;
  font-weight: 400;
  text-decoration: none;
}

/* Change the link color to #111 (black) on hover */
li a:hover {
  background-color: 
}

.nav-link:hover {
  color: var(--blue);
}

@media only screen and (min-width: 600px) {
  .dropdown-container {
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .made-with {
    padding: 1% 2% 2% 2%;
    font-size: 1.15em;
  }

  li {
    float: none;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .icons {
    display: flex;
    flex-direction: row;
  }

  .home-link {
    display: none;
  }

  .nav-link {
    display: none;
  }

  /* Menu dropdown */
  .dropdown-container {
    position: relative;
    float: right;
  }
  
  .dropdown-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
  }

  .dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    width: 100px;
    padding: 10px;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }

  .dropdown li:hover {
    background-color: #ddd;
  }
}



