.about-txt {
    padding: 10%; 
}

.flex-container {
    display: flex;
    flex-direction: row;
}

.description {
    line-height: 2em;
}

.profile-pic {
    max-width: 400px;
    max-height: 400px;
    margin-left: 10%;
    border: 10px solid var(--blueBack);
    border-radius: 10%;
}

.blue-link {
    color: var(--blue);
    border-bottom: 2px solid var(--blue) ;
    text-decoration: none;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
   .flex-container {
       flex-direction: column-reverse;
   }

   .profile-pic {
       width: 100%;
       margin: 20px 0px 20px 0px;
    
   }
}