@import url('https://fonts.googleapis.com/css?family=Work+Sans');

body {
  margin: 0;
  padding: 15px;
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-weight: bolder;
  color: #161616;
  font-size: 3em;
  margin: 10px 0px 10px 0px;
}

h2 {
  font-weight: 600;
  color: #161616;
  font-size: 2.5em;
  margin: 5px 0px 5px 0px;
}

h3 {
  font-weight: 600;
  color: #161616;
  font-size: 1.5em;
  margin: 5px 0px 5px 0px;
}

h4 {
  font-weight: 400;
  color: #161616;
  font-size: 1.2em;
  margin: 5px 0px 5px 0px;
}

p {
  font-weight: 100;
  color: #1a0c0c;
  font-size: 1em;
}

