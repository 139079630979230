
.hero {
    display: flex;
    flex-direction: row;
    padding: 10% 0% 10% 0%;
    width: 100%;
}

.hero-img {
    padding: 0% 5% 0% 5%;
}

img {
    max-width: 100%;
    height: auto;
}

.hero-txt {
    display: flex;
    flex-direction: column;
    padding: 0% 5% 0% 5%;
}

.accent-txt {
    color: var(--blue);
}

.underlined {
    padding-bottom: 2%;
    border-bottom: 3px solid black;
    font-size: 1.75em;
    max-width: 150px;
}

.blue-button {
    background-color: #E1EBFF; 
    border-radius: 24px;
    border: none;
    color: var(--blue);
    font-weight: 600;
    padding: 2% 4%;
    margin: 4% 0% 4% 0%;
    width: fit-content;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.25em;
    outline: none; 
    cursor: pointer;
}

.portfolio {
    margin: 0% 5% 0% 5%;
}


.projects {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-grow: 1;
    padding: 5% 10% 10% 10%;
}

@supports not (-ms-ime-align: auto) {
    .projects {
      justify-content: space-evenly;
      padding: 5%;
    }
  }

.link-style {
    text-decoration: none;
}

.prj {
    flex-direction: row;
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 1024px) {
    .projects {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 5%;
    }

    .hero {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: auto;
    }

    .hero-img {
        max-width: 100%;    
        max-height: 100%;
        width: 100%;
        text-align: center;
        padding: 0;
    }

    .hero-txt {
        padding: 10% 0% 10% 0%;
        text-align: center;
    }

    .underlined {
        text-align: center;
        margin: auto;
    }
}

